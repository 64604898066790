import {
  RESET_STATE,
  GET_TREE_CUTTING_APPLICATIONS,
  CREATE_TREE_CUTTING_APPLICATION,
  GET_EXCEL_TREE_CUTTING_APPLICATIONS,
  GET_CENSUS_TREE_BY_COUNCIL_OR_PROPERTY,
  CREATE_TREE_CUTTING_MOM,
  GET_TREE_CUTTING_MOM,
  GET_TREE_CUTTING_COMPENSATION_FINE_DETAILS,
  POST_TREE_CUTTING_COMPONSATION_FINE,
  GET_TREE_NEED_TO_BE_PLANT_LIST,
  UPDATE_TREE_NEED_TO_BE_PLANT,
  GET_PERMISSION_LETTER_DETAILS,
  GET_DEMANDED_TREE_CUTTING_LIST,
  SEARCH_TREE_CUTTING_APPLICATION,
  ADD_SITEINSCPECTOR,
  GET_TREE_CUTTING_APPLICATION_BY_ID,
  UPDATE_TREE_CUTTING_APPLICATION,
} from '../actions/Types';

const INIT_STATE = {
  pageInfo: {},
  censusTreePageInfo: {},
  treeCuttingApplications: [],
  excelTreeCuttingApplications: [],
  addTreeCuttingApplicationLog: false,
  updateTreeCuttingApplicationLog: false,
  censusTree: [],
  treeCuttingMom: [],
  addTreeCuttingMomLog: false,
  treeCuttingFineDetails: {},
  treeCuttingFineDetailsLog: false,
  postTreeCuttingFineDetailsLog: false,
  treeNeedToBePlant: [],
  treeNeedToBePlantLog: false,
  permissionLetterDetails: {},
  permissionLetterDetailsLog: false,
  demandedTreesCuttingDetails: {},
  demandedTreesCuttingDetailsLog: false,
  siteinspector: null,
  singleApplicationData: [],

  // searchTreeCuttingApplications: [],
};

export default function TreeCuttingPermissionReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TREE_CUTTING_APPLICATIONS:
      return {
        ...state,
        treeCuttingApplications: payload.data.data,
        pageInfo: payload.data,
      };
    case GET_TREE_CUTTING_APPLICATION_BY_ID:
      return {
        ...state,
        singleApplicationData: payload.data.data,
        pageInfo: payload.data,
      };

    case GET_EXCEL_TREE_CUTTING_APPLICATIONS:
      return {
        ...state,
        excelTreeCuttingApplications: payload.data,
      };

    case CREATE_TREE_CUTTING_APPLICATION:
      return {
        ...state,
        addTreeCuttingApplicationLog: !state.addTreeCuttingApplicationLog,
      };
    case UPDATE_TREE_CUTTING_APPLICATION:
      return {
        ...state,
        updateTreeCuttingApplicationLog: !state.updateTreeCuttingApplicationLog,
      };

    case GET_CENSUS_TREE_BY_COUNCIL_OR_PROPERTY:
      return {
        ...state,
        censusTree: payload.data.data,
        censusTreePageInfo: payload.data,
      };

    case GET_TREE_CUTTING_MOM:
      return {
        ...state,
        treeCuttingMom: payload.data.data,
        pageInfo: payload.data,
      };

    case CREATE_TREE_CUTTING_MOM:
      return {
        ...state,
        addTreeCuttingMomLog: !state.addTreeCuttingMomLog,
      };

    case GET_TREE_CUTTING_COMPENSATION_FINE_DETAILS:
      return {
        ...state,
        treeCuttingFineDetails: payload.data,
        treeCuttingFineDetailsLog: !state.treeCuttingFineDetailsLog,
      };

    case POST_TREE_CUTTING_COMPONSATION_FINE:
      return {
        ...state,
        postTreeCuttingFineDetailsLog: !state.postTreeCuttingFineDetailsLog,
      };

    case GET_TREE_NEED_TO_BE_PLANT_LIST:
      return {
        ...state,
        treeNeedToBePlant: payload.data,
      };
    case UPDATE_TREE_NEED_TO_BE_PLANT:
      return {
        ...state,
        treeNeedToBePlantLog: !state.treeNeedToBePlantLog,
      };

    case GET_PERMISSION_LETTER_DETAILS:
      return {
        ...state,
        permissionLetterDetails: payload.data,
        permissionLetterDetailsLog: !state.permissionLetterDetailsLog,
      };
    case GET_DEMANDED_TREE_CUTTING_LIST:
      return {
        ...state,
        demandedTreesCuttingDetails: payload.data,
        demandedTreesCuttingDetailsLog: !state.demandedTreesCuttingDetailsLog,
      };

    case SEARCH_TREE_CUTTING_APPLICATION:
      return {
        ...state,
        treeCuttingApplications: payload.data.data,
        pageInfo: payload.data,
      };

    case ADD_SITEINSCPECTOR:
      return {
        ...state,
        siteinspector: payload.data.data,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
}
