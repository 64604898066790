import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Stack,
  Card,
  Autocomplete,
  CardMedia,
  IconButton,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  MenuItem,
  InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { GetNewsPapers } from '../../actions/NewsPaperAction';
import { AddNewsPaperProclamation } from '../../actions/ProclamationActions';




const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadProclamation = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [documentError, setDocumentError] = useState(null);
  const [newspaperData, setNewspaperData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState('');
  const [imageValue, setImageValue] = useState(null);


  const dispatch = useDispatch();
  const location = useLocation();
  console.log("reciptloaction",location)


  const { newspaper } = useSelector((state) => ({
    newspaper: state.NewsPaperReducer.newsPapers,
  }));

  useEffect((data) => {
    setLoader(true);
    dispatch(GetNewsPapers(0, 0)).then((response) => {
    
      if (response?.status === 200) {
        setNewspaperData(data?.data?.newspaper);
        setLoader(false);
      }
    });
  }, []);

  // const {}

  const formik = useFormik({
    initialValues: {
      publishcharges: '',
      newspaper: null,
      Date: '',
      Time: '',
      Isrecived: '',
      image: null,
      files: null,
    },
    validationSchema: Yup.object({
      newspaper: Yup.mixed().required('Please select newspaper'),
      publishcharges: Yup.string().required('Publishing charges is required').matches(/^\d{1,4}$/, 'Publishing charges must be up to 4 digits and contain only numbers') ,
 
      Date: Yup.date().required('Date is required')
    ,
      Time: Yup.string().required('Waiting Days is required').test('not-zero', 'Waiting days cannot be 0', value => value !== '0').matches(/^\d{1,2}$/, 'Waiting Days must be 1 or 2 digits'),
      Isrecived: Yup.string().required('Please select an option'),
     image: Yup.string().required('News Image is required').nullable(),
     files: Yup.string()
    .nullable()
    .when('Isrecived', (Isrecived, schema) =>
      Isrecived === 'Yes'
        ? schema.required('Receipt is required')
        : schema.notRequired()
    ),

    }),
    onSubmit: (values) => {
      console.log('Form Values:', values);
      const formData = new FormData();
      formData.append('image', values.image);
      formData.append('tree_cutting_application_id', location.state.id);
      formData.append('newspaper_id', id);
      formData.append('published_date', values.Date);
      formData.append('fees', values.publishcharges);
      formData.append('is_fees_received', values.Isrecived);
      formData.append('fees_status', 'Unpaid');
      if(values.files){
        formData.append('fees_receipt', values.files);
      }
     
      formData.append('objection_received', '');
      formData.append('objection_by_user', '');
      formData.append('objection_date', '');
      formData.append('waiting_days_for_objections', values.Time);
      dispatch(AddNewsPaperProclamation(formData)).then((response) => {
        if (response.status === 201) {
          navigate('/dashboard/tree-cutting-application');
        }
      });
    },
  });

  const handleRemoveDocument = () => {
    setSelectedDocument(null);
    formik.setFieldValue('document', '');
  };

  const handleNewsPaperChange = (e) => {
    setNewspaperData(e.target.value);
    formik.setFieldValue('newspaper', '');
  };
  const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
const allowImge = ['image/jpeg', 'image/jpg', 'image/png'];

  const handleImageChange = (event) => {

    const image = event.target.files[0];
    // const maxFileSize = 14 * 1024 * 1024;
    const maxFileSize = 5 * 1024 * 1024; 
    const isValidFiles = allowedImageTypes.includes(image.type);
    const isValidSizes = image.size <= maxFileSize;
    if (!isValidFiles) {
      setImageError('Only image accepted');
      formik.setFieldError('image', 'File format not supported. Only image accepted');
      setSelectedImage('');
    } else if (!isValidSizes) {
      formik.setFieldError('image', 'File size exceeds 14 MB');
    } else if (isValidFiles && isValidSizes) {
      setImageError(null);
      setSelectedImage(image);
      setImageValue(image.name)
      formik.setFieldValue('image', event.target.files[0]);
    }
  };
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'application/pdf'];
const allowFile = ['image/jpeg', 'image/jpg', 'image/png'];

  const handleDocumentChange = (event) => {
    const document = event.target.files[0];
    setSelectedDocument(document)
    const maxFileSize = 5 * 1024 * 1024; 

    const isValidFiles = allowedFileTypes.includes(document.type);
    const isValidSizes = document.size <= maxFileSize;

    if (!isValidFiles) {
      setDocumentError('File format not supported');
     
      selectedDocument('')
      formik.setFieldError('files', 'File format not supported');
    } else if (!isValidSizes) {
      formik.setFieldError('files', 'File size exceeds 14 MB');
    } else if (isValidFiles && isValidSizes) {
      setDocumentError(null);
      formik.setFieldValue('files', event.target.files[0]);
    }
  };
  const [radioValue, setRadioValue] = useState();
  const handleRadio = (e) => {
 
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    console.log('dispatchnewsPaper', dispatch(GetNewsPapers(0, 0)));
  }, []);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleImageRemove = () => {
    setImageValue('');
    setSelectedImage(null);
    formik.setFieldValue('image', '');
  };

  
  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {'Add Proclamation'}
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography>
        </Typography>
      </Stack>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {/* select news */}
            <Grid item xs={6}>
            <Autocomplete
        options={newspaper || []}
        getOptionLabel={(option) => option.newspaper || ''}
        value={formik.values.newspaper || null} // Ensure value is either the object or null
        onChange={(event, newValue) => {
          const { id } = newValue || {};
          setId(id);
          formik.setFieldValue('newspaper', newValue); // Update Formik field
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="newspaper"
            variant="outlined"
            label="Select News Paper"
            placeholder="Select News Paper"
            error={formik.touched.newspaper && Boolean(formik.errors.newspaper)}
            helperText={formik.touched.newspaper && formik.errors.newspaper}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDownIcon  sx={{color:"gray"}}/>
                </InputAdornment>
              ),
            }}
          />
        )}
        filterSelectedOptions
      />
    </Grid>

            
            {/* meetingDate */}
            <Grid item xs={6}>
              <TextField
                id="Date"
                name="Date"
                type="date"
                label="Published date*"
                fullWidth
                error={Boolean(formik.touched.Date && formik.errors.Date)}
                helperText={formik.touched.Date && formik.errors.Date}
                value={formik.values.Date}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date().toISOString().split('T')[0],
                }}
              />
            </Grid>

            {/* waiting Time */}
            <Grid item xs={6} sx={{ marginTop: '25px' }}>
              <TextField
                fullWidth
                id="Time"
                name="Time"
                label="Waiting Days*"
                variant="outlined"
                value={formik.values.Time}
                onChange={formik.handleChange}
                error={formik.touched.Time && Boolean(formik.errors.Time)}
                helperText={formik.touched.Time && formik.errors.Time}
              />
            </Grid>

            {/* upload image */}
            <Grid item xs={6}>
              {!selectedImage ? (
                <>
                  <Typography> News Paper Image*</Typography>
                  <TextField
                  id="image"
                    name="image"
                    // accept="image/*"
            
                    // value={imageValue}
                    fullWidth
                    type="file"
                    onChange={handleImageChange}
                    error={formik.touched.image && Boolean(formik.errors.image)}
                    // helperText={formik.touched.image && formik.errors.image}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    aria-describedby="file-upload-label"
                  />
                  <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: '0.75rem' }}>
                    {formik.touched.image && (formik.errors.image || imageError)}
                  </Typography>
                </>
              ) : (
                <>
                  <p>{imageValue}</p>
                  <div
                    style={{
                      position: 'relative',
                      width: '100px',
                    }}>
                    <div
                      style={{
                        width: '100px',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '10px',
                      }}>
                      <DescriptionIcon style={{ fontSize: 36 }} />
                    </div>

                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        background: 'red',
                        width: '10px',
                        height: '10px',
                      }}
                      onClick={() => handleImageRemove()}>
                      <CloseIcon style={{ color: 'white', fontSize: 16 }} />
                    </IconButton>
                  </div>
                </>
              )}
            </Grid>

            {/* publish charges */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="publishcharges"
                name="publishcharges"
                  label="Publishing Charges (₹)*"
                variant="outlined"
                value={formik.values.publishcharges}
                onChange={formik.handleChange}
                error={formik.touched.publishcharges && Boolean(formik.errors.publishcharges)}
                helperText={formik.touched.publishcharges && formik.errors.publishcharges}
              />
            </Grid>

            {/* checkbox */}
            <Grid item xs={6}>
              <FormControl sx={{ mt: 1 }} error={formik.touched.Isrecived && Boolean(formik.errors.Isrecived)}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <FormLabel id="demo-row-radio-buttons-group-label">Is publishing fees received?</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="Isrecived"
                    value={formik.values.Isrecived}
                    onChange={formik.handleChange}>
                    <FormControlLabel
                      value="Yes"
                      control={<Radio sx={{ color: '#214c50', fontWeight: 700 }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio sx={{ color: '#214c50', fontWeight: 700 }} />}
                      label="No"
                    />
                  </RadioGroup>
                </Stack>
                <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: '0.75rem' }}>
                  {formik.touched.Isrecived && formik.errors.Isrecived}
                </Typography>
              </FormControl>
            </Grid>

            {formik.values.Isrecived === 'Yes' && (
              <Grid item xs={6}>
                {!selectedDocument ? (
                  <>
                    <input
                      // accept=".pdf,image"
                      style={{ display: 'none' }}
                      id="document-upload"
                      type="file"
                      onChange={handleDocumentChange}
                      name="files"

                    />
                    <Button
                      onClick={() => document.getElementById('document-upload').click()}
                      sx={{
                        fontWeight: 500,
                        width: '50%',
                        height: '50px',
                        padding: '10px',
                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        },
                      }}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <DescriptionIcon />
                        <span>Upload Receipt</span>
                      </Stack>
                    </Button>
                    <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: '0.75rem' }}>
                      {formik.touched.files && (documentError || formik.errors.files)}
                    </Typography>
                   
                  </>
                ) : (
                  <Grid item xs={6}>
                    <Typography>Uploaded receipt-</Typography>
                    <div
                      style={{
                        width: '100px',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '10px',
                        position: 'relative',
                      }}>
                      <DescriptionIcon style={{ fontSize: 36 }} />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          background: 'red',
                          width: '10px',
                          height: '10px',
                        }}
                        onClick={() => handleRemoveDocument()}>
                        <CloseIcon style={{ color: 'white', fontSize: 16 }} />
                      </IconButton>
                    </div>
                    <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: '0.75rem' }}>
                      {formik.touched.document && formik.errors.document}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}

            {/* Submit Button */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
    onClick={(e) => {
    
      formik.handleSubmit(e);
    }}
              variant="contained" color="primary" type="submit" sx={{ width: '14%' }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default UploadProclamation;
