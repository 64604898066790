import React, { useState, useRef } from 'react';
import {
  CardActionArea,
  CardMedia,
  CardContent,
  CircularProgress,
  Typography,
  Grid,
  Button,
  Divider,
  Card,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
  Modal
} from '@mui/material';
import { format } from 'date-fns';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { green, orange, red, gray } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import TreeDetailsWithReasonDialog from '../../../components/DialogBox/TreeCutting/TreeDetailsWithReasonDialog';
import { GetSpecificTreeInfo } from '../../../actions/TreeOnMapAction';

const InspectionReport = ({ applicationDetailsData, showLoader }) => {
  const carouselRef = useRef(null);
  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const dispatch = useDispatch();
  const { treeDetails, loggedUser } = useSelector((state) => ({
    treeDetails: state.treeLocation.treeDetails,
    loggedUser: state.auth.loggedUser,
  }));
  const [treeDetailsOpen, setDialogOpen] = useState(false);
  const [activeChild, setActiveChild] = useState(0);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'MMMM d, yyyy h:mm a');
  };
  const [open, setOpen] = useState(false);
  const [openb, setOpenb] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenb = () => setOpenb(true);
  const handleCloseb = () => setOpenb(false);
  return (
    <Carousel
      index={activeChild}
      autoPlay={false}
      navButtonsAlwaysVisible
      animation="slide"
      duration={1000}
      indicatorIconButtonProps={{
        style: {
          // color: '#214c50',
          marginTop: 35,
        },
      }}
      navButtonsProps={{
        style: {
          color: '#214c50',
          backgroundColor: 'transparent',
          border: 'none',
        },
      }}>
      {applicationDetailsData?.cutting_trees?.map((reportsData) => {
        return (
          <Card style={{ margin: 10, padding: 20 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItem: 'center',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Typography variant="h4">{reportsData?.tree_number}</Typography>
            </Box>

            <Divider sx={{ marginY: '20px' }} />

            <Grid container spacing={2}>
              <Grid item lg={5} md={12} xs={12}>
                <Box sx={{ marginTop: '10px' }}>
                  <Typography sx={{ fontWeight: '700' }}>Reason by applicant</Typography>
                  <Typography>{reportsData?.tree_cutting_reason?.reason}</Typography>
                </Box>
                <Box sx={{ marginTop: '10px' }}>
                  <Typography sx={{ fontWeight: '700' }}>Tree Name by applicant</Typography>
                  <Typography>{reportsData?.tree_name?.name}</Typography>
                </Box>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          borderRadius: '10px',
                          backgroundColor: '#F5F5F5',
                          color: '#1C1B1F',
                          margin: '10px',
                          padding: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}>
                        <Typography>Tree Girth</Typography>

                        <Typography sx={{ fontWeight: 600 }}>
                          {' '}
                          {reportsData?.site_inspection_report?.girth
                            ? reportsData?.site_inspection_report?.girth
                            : 'NA'}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box
                        sx={{
                          borderRadius: '10px',
                          backgroundColor: '#F5F5F5',
                          color: '#1C1B1F',
                          margin: '10px',
                          padding: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}>
                        <Typography>Age as per girth</Typography>
                        {reportsData?.site_inspection_report?.age_by_girth ? (
                          <Typography sx={{ fontWeight: 600 }}>
                            {reportsData?.site_inspection_report?.age_by_girth} Years
                          </Typography>
                        ) : (
                          <Typography sx={{ fontWeight: 600 }}>NA</Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          borderRadius: '10px',
                          backgroundColor: '#F5F5F5',
                          color: '#1C1B1F',
                          margin: '10px',
                          padding: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}>
                        <Typography>Plantation Date</Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          {reportsData?.site_inspection_report?.plantation_date
                            ? reportsData?.site_inspection_report?.plantation_date
                            : 'NA'}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={6}>
                      <Box
                        sx={{
                          borderRadius: '10px',
                          backgroundColor: '#F5F5F5',
                          color: '#1C1B1F',
                          margin: '10px',
                          padding: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}>
                        <Typography>Age as per plantation</Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                          {reportsData?.site_inspection_report?.age_by_plantation_date
                            ? reportsData?.site_inspection_report?.age_by_plantation_date
                            : 'NA'}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Box sx={{ position: 'relative' }}>
                  <Typography>Image by applicant</Typography>

                  <CardMedia
                    component="img"
                    height="200"
                    width="200"
                    image={`${process.env.REACT_APP_BASE_URL}/${reportsData?.image_url}`}
                    sx={{ borderRadius: '20px' }}
                    alt="Image"
                    onClick={handleOpenb}
                  />
                  <IconButton
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: '0 0 5px rgba(0,0,0,0.2)',

                      width: '25px',
                      height: '25px',
                      position: 'absolute',
                      top: 25,
                      right: 5,
                    }}
                    onClick={handleOpenb}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Box>

                <Box sx={{ position: 'relative',marginTop:"20px" }}>
                  <Typography>Image by inspector</Typography>
                  <CardMedia
                    component="img"
                    height="200"
                    width="200"
                    sx={{ borderRadius: '20px' }}
                    image={`${process.env.REACT_APP_BASE_URL}/${reportsData?.site_inspection_report?.image_url}`}
                    alt="Image"
                    onClick={handleOpen}
                  />
                  <IconButton
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: '0 0 5px rgba(0,0,0,0.2)',

                      width: '25px',
                      height: '25px',
                      position: 'absolute',
                      top: 25,
                      right: 5,
                    }}
                    onClick={handleOpen}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Box>


                <Modal
                  open={openb}
                  onClose={handleCloseb}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <IconButton
                      onClick={handleCloseb}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: 'black',
                      }}>
                      <CloseIcon />
                    </IconButton>

                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${reportsData?.image_url}`}
                      alt="img"
                      height="600px"
                      width="600px"
                      style={{ borderRadius: '15px', objectFit: 'cover' }}
                    />
                  </Box>
                </Modal>
              </Grid>
              {/* <Grid item lg={2}> */}
              {/* <Divider orientation="vertical" flexItem  sx={{color: "#D9D9D9", marginLeft: 10, marginTop: 5}} /> */}
              {/* </Grid> */}

              {/* lg: 'block' */}
              <Grid item lg={1} sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Divider orientation="vertical" flexItem sx={{ color: '#D9D9D9', height: '100%' }} />
              </Grid>

              <Grid item lg={5} md={12} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Box sx={{ marginTop: '10px' }}>
                    <Typography sx={{ fontWeight: 700 }}>Inspection Date & Time</Typography>

                    <Typography sx={{ color: '#1C1B1F', fontWeight: 300 }}>
                      {' '}
                      {formatDate(reportsData?.site_inspection_report?.created_at)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginTop: '10px' }}>
                  <Typography sx={{ fontWeight: '700' }}>Tree Name by Inspector</Typography>
                  <Typography>{reportsData?.site_inspection_report?.tree_name?.name}</Typography>
                </Box>
                <Box sx={{ marginTop: '15px' }}>
                  <Typography> Inspection Done By</Typography>

                  <Typography sx={{ fontWeight: 700 }}>
                    {' '}
                    {reportsData?.site_inspection_report?.site_inspection.site_inspector.full_name}
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '15px',
                  }}>
                  <Typography>Is given reason valid/invalid ?</Typography>
                  {reportsData?.site_inspection_report ? (
                    reportsData.site_inspection_report.given_reason === 'Valid' ? (
                      <Chip label="Valid" style={{ color: 'white', backgroundColor: green[600], fontWeight: '700' }} />
                    ) : reportsData.site_inspection_report.given_reason === 'Invalid' ? (
                      <Chip label="Invalid" style={{ color: 'white', backgroundColor: red[600], fontWeight: '700' }} />
                    ) : null
                  ) : null}
                </Box>
                {/* <Box sx={{ marginTop: '10px' }}>
                  <Typography sx={{ fontWeight: '700' }}>Reason by inspector</Typography>
                  <Typography>{reportsData?.tree_cutting_reason?.reason}</Typography>
                </Box> */}
                <Box sx={{ marginTop: '15px' }}>
                  <Typography sx={{ fontWeight: '700' }}>Remark by inspector</Typography>
                  <Typography sx={{ display: 'flex', flexWrap: 'wrap', fontWeight: '700' }}>
                    {reportsData?.site_inspection_report?.remark}
                  </Typography>
                </Box>

                

                {/* girth image */}
                <Box sx={{ position: 'relative' }}>
                  <Typography sx={{ fontWeight: '700' }}>Girth image by inspector</Typography>
                  <CardMedia
                    component="img"
                    height="200"
                    width="200"
                    sx={{ borderRadius: '20px' }}
                    image={`${process.env.REACT_APP_BASE_URL}/${reportsData?.site_inspection_report?.girth_image_url}`}
                    alt="Image"
                    onClick={handleOpen}
                  />
                  <IconButton
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: '0 0 5px rgba(0,0,0,0.2)',

                      width: '25px',
                      height: '25px',
                      position: 'absolute',
                      top: 25,
                      right: 5,
                    }}
                    onClick={handleOpen}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Box>

                <Modal
                  open={open}
                  onClose={handleClose}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <IconButton
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: 'black',
                      }}>
                      <CloseIcon />
                    </IconButton>

                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${reportsData?.site_inspection_report?.image_url}`}
                      alt="img"
                      height="600px"
                      width="600px"
                      style={{ borderRadius: '15px', objectFit: 'cover' }}
                    />
                  </Box>
                </Modal>

                <Box style={{ marginTop: '15px' }}>
                  <Typography sx={{ fontWeight: '700' }}>Observations by Inspector</Typography>
                  <List>
                    {reportsData?.site_inspection_report?.observations.map((observation, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          '&::before': {
                            content: '"•"',
                            display: 'inline-block',
                            width: '0.5em',
                            marginRight: 0,
                            color: 'text.primary',
                            fontSize: '1rem',
                          },
                        }}>
                        <Typography>{observation.reason}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Card>
        );
      })}
      {/* </Card> */}
    </Carousel>
  );
};

export default InspectionReport;
